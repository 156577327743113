import { Component } from "react";

const withLandingWrapper = (Page) => {
  class WithLandingWrapper extends Component {
    render() {
      return <Page {...this.state} {...this.props} />;
    }
  }

  return WithLandingWrapper;
};

export default withLandingWrapper;
