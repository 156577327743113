import { motion } from "framer-motion";

const PromptDoodle = ({
  showDoodleWithArrow,
  shouldAnimate = true,
  isVisible = false,
  duration = 1,
  delay: initialDelay = 0,
}) => {
  const componentName = "at-prompt-doodle";
  const inactive = {
    hidden: { pathLength: 0, strokeLinecap: "initial" },
    visible: () => {
      return {};
    },
  };

  const draw = {
    hidden: { pathLength: 0, strokeLinecap: "initial" },
    visible: (i = 1) => {
      const delay = initialDelay + i * (duration - 0.5);

      return {
        pathLength: 1,
        strokeLinecap: "round",
        transition: {
          pathLength: { delay, type: "spring", duration, bounce: 0 },
          strokeLinecap: { delay },
        },
      };
    },
  };

  const DoodleWithArrow = () => (
    <motion.svg
      xmlns="http://www.w3.org/2000/svg"
      fill="transparent"
      stroke="var(--prompt-doodle-color, var(--o-500-color))"
      viewBox="0 0 40 114"
      className={componentName}
      aria-hidden="true"
      focusable="false"
      tabIndex={-1}
      initial="hidden"
      animate="visible"
      strokeLinecap={"round"}
      strokeWidth={4}
    >
      <motion.path
        className="st0"
        d="m 16.2,2 c -18,44.4 -5.5,60.9 2.8,66 5.7,3.6 17.5,-1.3 18.6,-10.7 1,-8.2 -5.2,-9.6 -8.5,-9.4 -26.4,1.8 -13.109323,60.40122 -13.009323,61.30122"
        variants={shouldAnimate ? (isVisible ? draw : inactive) : undefined}
        custom={1}
      />
      <motion.path
        className="st0"
        d="M2.1,101.4c0,0,6.3,0.6,14,10c0.7,0.9,1.6,1,1.8-0.2c1-7.1,2.3-9.5,6.1-13.4"
        variants={shouldAnimate ? (isVisible ? draw : inactive) : undefined}
        custom={2}
      />
    </motion.svg>
  );
  const DoodleWithoutArrow = () => (
    <motion.svg
      xmlns="http://www.w3.org/2000/svg"
      fill="transparent"
      stroke="var(--prompt-doodle-color, var(--o-500-color))"
      strokeLinecap={"round"}
      strokeWidth={4}
      viewBox="0 0 35 110"
      className={componentName}
      aria-hidden="true"
      focusable="false"
      tabIndex={-1}
      initial="hidden"
      animate="visible"
      custom={1}
    >
      <motion.path
        d="M24.3,2C24.4,2.9,37,60.5,10.6,62.3c-3.3,0.2-9.5-1.2-8.5-9.4C3.2,43.5,15,38.6,20.7,42.2
	c8.3,5.1,20.8,21.6,2.8,66"
        variants={shouldAnimate ? (isVisible ? draw : inactive) : undefined}
      />
    </motion.svg>
  );

  return showDoodleWithArrow ? <DoodleWithArrow /> : <DoodleWithoutArrow />;
};

export default PromptDoodle;
