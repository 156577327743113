// extracted by mini-css-extract-plugin
export var aboutUsLanding = "AboutUsLanding-module--aboutUsLanding--UZxmX";
export var main = "AboutUsLanding-module--main--PO+9G";
export var heroAboutUs = "AboutUsLanding-module--heroAboutUs--4Zirk";
export var heroLogo = "AboutUsLanding-module--heroLogo--xshiQ";
export var sectionTitle = "AboutUsLanding-module--sectionTitle--cypiN";
export var numbers = "AboutUsLanding-module--numbers--QZwxX";
export var whoAreWe = "AboutUsLanding-module--whoAreWe--XjgEj";
export var employeeCarousel = "AboutUsLanding-module--employeeCarousel--GmpVc";
export var pageWrapper = "AboutUsLanding-module--page-wrapper--mo70w";
export var ourValues = "AboutUsLanding-module--ourValues--mqV5R";
export var ourHistory = "AboutUsLanding-module--ourHistory--cvRmI";
export var ourHistoryDescription = "AboutUsLanding-module--ourHistoryDescription--OXxmJ";
export var ourHistoryImage = "AboutUsLanding-module--ourHistoryImage--c-nCI";
export var cta = "AboutUsLanding-module--cta--pFJgs";
export var aboutUsBottomCta = "AboutUsLanding-module--aboutUsBottomCta--0PgCx";