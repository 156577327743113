import { useInView } from "framer-motion";
import { useEffect, useRef, useState } from "react";

import { trackSectionVisibility } from "../analytics";
import useBeforeunload from "./useBeforeUnload";

function trackTimeViewingSection(timeStartedVisible, sectionName, extraProperties) {
  if (timeStartedVisible) {
    const timeVisible = Date.now() - timeStartedVisible;
    trackSectionVisibility(sectionName, Math.floor(timeVisible / 1000), extraProperties);
  }
}

const useVisibilityTracker = (sectionName, intersectionOptions = {}, extraProperties = {}) => {
  const [timeStartedVisible, setTimeStartedVisible] = useState(null);
  const [timesVisited, setTimesVisited] = useState(0);
  const [isVisible, setIsVisible] = useState(false);
  const ref = useRef(null);

  const inView = useInView(ref, intersectionOptions);

  if (isVisible !== inView) {
    if (inView) {
      setTimesVisited(timesVisited + 1);
      setTimeStartedVisible(Date.now());
    }
    setIsVisible(inView);
  }

  useEffect(() => {
    if (timesVisited > 0 && !isVisible) {
      trackTimeViewingSection(timeStartedVisible, sectionName, extraProperties);
    }
  }, [isVisible, sectionName, timeStartedVisible, timesVisited]);

  useBeforeunload(() => {
    trackTimeViewingSection(timeStartedVisible, sectionName, extraProperties);
  });

  return { ref, isVisible };
};

export default useVisibilityTracker;
