import cn from "classnames";
import { useReducedMotionConfig } from "framer-motion";
import { graphql } from "gatsby";

import PromptDoodle from "../../atoms/prompt-doodle/prompt-doodle";
import TrackableLink from "../../atoms/trackable-link/TrackableLink";
import { CountryCode } from "../../settings/countries";
import { Events } from "../../utils/analytics";
import useVisibilityTracker from "../../utils/hooks/useVisibilityTracker";
import * as styles from "./LandingBottomCta.module.scss";

interface LandingBottomCtaProps {
  data: {
    cta: string;
    subtitle?: string;
  };
  countryCodeIso: CountryCode;
  adoptionClassName?: string;
}

const LandingBottomCta = ({
  data,
  countryCodeIso = CountryCode.ES,
  adoptionClassName,
}: LandingBottomCtaProps): JSX.Element => {
  const reduceMotion = useReducedMotionConfig();
  const { ref, isVisible } = useVisibilityTracker("home-bottom-cta", {
    threshold: 0.7,
  });
  const { cta, subtitle = "" } = data;

  return (
    <section
      ref={ref}
      className={cn(styles.homeBottomCta, adoptionClassName, "or-home-bottom-cta")}
    >
      <PromptDoodle
        showDoodleWithArrow={true}
        shouldAnimate={!reduceMotion}
        isVisible={isVisible}
      />
      <TrackableLink
        to={`/${countryCodeIso.toLowerCase()}/onboarding/species/`}
        eventName={Events.CLICKED_CTA}
        isExternalLink={false}
        adoptionClassName={cn(styles.link)}
        eventSender={"Bottom Home CTA"}
      >
        {cta}
      </TrackableLink>

      <p className={cn(styles.subtitle)}>{subtitle}</p>
    </section>
  );
};

export default LandingBottomCta;

export const query = graphql`
  fragment LandingBottomCtaFragment on ContentfulLandingBottomCta {
    cta
    subtitle
  }
`;
